@use '@angular/material' as mat;

@include mat.core();

$customBlue-palette: (
  50 : #e2e3e5,
  100 : #b7babf,
  200 : #888c94,
  300 : #585d69,
  400 : #343b48,
  500 : #101828,
  600 : #0e1524,
  700 : #0c111e,
  800 : #090e18,
  900 : #05080f,
  A100 : #5370ff,
  A200 : #008cee,
  A400 : #0028ec,
  A700 : #0024d2,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$my-primary: mat.define-palette($customBlue-palette, A200, A100, A400);
$my-accent: mat.define-palette($customBlue-palette, A200, A100, A400);
$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
      ),
      typography: mat.define-typography-config(),
      density: 0,
    ));

@include mat.all-component-themes($my-theme);

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #101828;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-handle-color: #008cee;
  --mdc-switch-selected-focus-state-layer-color: #008cee;
  --mdc-switch-selected-hover-state-layer-color: #008cee;

  --mdc-switch-selected-track-color: #93bcd9;
  --mdc-switch-selected-pressed-track-color: #d6d8dd;
  --mdc-switch-selected-hover-track-color: #d6d8dd;
  --mdc-switch-selected-focus-track-color: #d6d8dd;

  --mdc-switch-selected-focus-handle-color: #008cee;
  --mdc-switch-selected-pressed-state-layer-color: #008cee;
  --mdc-switch-selected-focus-handle-color: #008cee;
  --mdc-switch-selected-hover-handle-color: #008cee;
  --mdc-switch-selected-pressed-handle-color: #008cee;

  &:not(.mat-mdc-slide-toggle-checked) {
    --mdc-elevation-overlay-color: white;
  }

}
