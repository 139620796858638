/* Apply a custom font style */
.ql-editor {
  font-family: 'Arial', sans-serif !important;
  font-size: 16px !important;
  line-height: 1.6 !important;
  text-align: justify !important;
}

/* Override Quill font styles */
.ql-font-serif {
  font-family: 'Georgia', serif !important;
}

.ql-font-monospace {
  font-family: 'Courier New', monospace !important;
}

/* Fix alignment and indentation */
.ql-align-justify {
  text-align: justify !important;
}

.ql-align-center {
  text-align: center !important;
}

.ql-align-right {
  text-align: right !important;
}

/* Custom line spacing */
.ql-editor p {
  margin: 8px 0 !important;
}