@import 'material-icons/iconfont/material-icons.css';
@import './editor-quill.css';
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: plain-bold;
  src: url('/assets/font/Plain-Bold.ttf') format("truetype");
  src: url('/assets/font/Plain-Bold.woff') format("woff");
  src: url('/assets/font/Plain-Bold.woff2') format("woff2");
}
@font-face {
  font-family: plain-regular;
  src: url('/assets/font/Plain-Regular.ttf') format("truetype");
  src: url('/assets/font/Plain-Regular.woff') format("woff");
  src: url('/assets/font/Plain-Regular.woff2') format("woff2");
}

html,
body {
  height: 100%;
  background-color: white;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.input-password {
  text-security: disc;
  -webkit-text-security: disc;
  -moz-text-security: disc;
}

.p-2 {
  padding: 2em;
}

.d-block {
  display: block;
}

.mb-05 {
  margin-bottom: 0.5em;
}

.mb-2 {
  margin-bottom: 2em;
}

.p-1 {
  padding: 1em;
}

.mr-05 {
  margin-right: 0.5em;
}

.d-flex {
  display: flex;
}

.item-center {
  align-items: center;
}

.rounded-dialog {
  .mat-mdc-dialog-surface {
    background-color: transparent;
  }
}

.release-notes-content {
  pre {
    background-color: #f4f4f4;
    padding: 10px;
    border: 1px solid #ccc;
    overflow: auto;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-height: 300px;
  }
}